<template>
  <div class="mt-4">
    <h4>
      {{ $t('FICHA_SAUDE.DEFICIENCIAS') }}
    </h4>
    <b-row>
      <b-col>
        <input-checkbox
          ref="fichaSaude.deficiencia"
          v-model="fichaSaude.deficiencia"
          class="mt-1"
          :label="$t('FICHA_SAUDE.DEFICIENCIAS_CONFIRMACAO')"
          :disabled="apenasVisualizar"
        />
        <input-text
          ref="fichaSaude.especificacaoDeficiencia"
          :required="fichaSaude.deficiencia"
          v-model="fichaSaude.especificacaoDeficiencia"
          :label="$t('FICHA_SAUDE.DEFICIENCIAS_ESPECIFIQUE')"
          :placeholder="$t('FICHA_SAUDE.DEFICIENCIAS_DESCRICAO_CID')"
          :disabled="!fichaSaude.deficiencia || apenasVisualizar"
        />
      </b-col>
      <b-col>
        <p>{{ $t('FICHA_SAUDE.DEFICIENCIAS_SELECIONE') }}</p>
        <b-row>
          <b-col>
            <input-checkbox
              ref="deficienciaFisica"
              v-model="deficienciaFisica"
              :label="$t('FICHA_SAUDE.DEFICIENCIA_FISICA')"
              :disabled="apenasVisualizar"
            />
            <input-checkbox
              ref="deficienciaVisual"
              v-model="deficienciaVisual"
              :label="$t('FICHA_SAUDE.DEFICIENCIA_VISUAL')"
              :disabled="apenasVisualizar"
            />
            <input-checkbox
              ref="deficienciaAuditiva"
              v-model="deficienciaAuditiva"
              :label="$t('FICHA_SAUDE.DEFICIENCIA_AUDITIVA')"
              :disabled="apenasVisualizar"
            />
          </b-col>
          <b-col>
            <input-checkbox
              ref="deficienciaIntelectual"
              v-model="deficienciaIntelectual"
              :label="$t('FICHA_SAUDE.DEFICIENCIA_INTELECTUAL')"
              :disabled="apenasVisualizar"
            />
            <input-checkbox
              ref="deficienciaPsicossocial"
              v-model="deficienciaPsicossocial"
              :label="$t('FICHA_SAUDE.DEFICIENCIA_PSICOSSOCIAL')"
              :disabled="apenasVisualizar"
            />
            <input-checkbox
              ref="deficienciaMultipla"
              v-model="deficienciaMultipla"
              :label="$t('FICHA_SAUDE.DEFICIENCIA_MULTIPLA')"
              :disabled="apenasVisualizar"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import InputText from '@/components/inputs/InputText.vue';
import helpers from '@/common/utils/helpers';

export default {
  props: ['fichaSaude', 'apenasVisualizar'],
  components: {
    InputCheckbox,
    InputText,
  },
  data() {
    return {
      deficienciaMultipla: false,
      deficienciaPsicossocial: false,
      deficienciaIntelectual: false,
      deficienciaAuditiva: false,
      deficienciaVisual: false,
      deficienciaFisica: false,
      tiposDeficiencias: [],
    };
  },
  watch: {
    deficienciaMultipla: 'multiplaSet',
    deficienciaPsicossocial: 'psicossocialSet',
    deficienciaIntelectual: 'intelectualSet',
    deficienciaAuditiva: 'auditivaSet',
    deficienciaVisual: 'visualSet',
    deficienciaFisica: 'fisicaSet',
    tiposDeficiencias: 'tiposDeficienciasSet',
    fichaSaude: 'validaDeficiencias',
  },
  mounted() {
    this.$emit('atualizarRefs', this.$refs);
  },
  methods: {
    validaDeficiencias() {
      if (this.fichaSaude?.tiposDeficiencias?.length && this.apenasVisualizar) {
        this.fichaSaude.tiposDeficiencias.forEach((item) => {
          if (item == 'Fisica') this.deficienciaFisica = true;
          if (item == 'Visual') this.deficienciaVisual = true;
          if (item == 'Auditiva') this.deficienciaAuditiva = true;
          if (item == 'Intelectual') this.deficienciaIntelectual = true;
          if (item == 'Psicossocial') this.deficienciaPsicossocial = true;
          if (item == 'Multipla') this.deficienciaMultipla = true;
        });
      }
    },
    multiplaSet() {
      if (this.deficienciaMultipla) {
        this.tiposDeficiencias.push(3);
      } else {
        const index = this.tiposDeficiencias.indexOf(3);
        if (index !== -1) {
          this.tiposDeficiencias.splice(index, 1);
        }
      }
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    psicossocialSet() {
      if (this.deficienciaPsicossocial) {
        this.tiposDeficiencias.push(4);
      } else {
        const index = this.tiposDeficiencias.indexOf(4);
        if (index !== -1) {
          this.tiposDeficiencias.splice(index, 1);
        }
      }
    },
    intelectualSet() {
      if (this.deficienciaIntelectual) {
        this.tiposDeficiencias.push(2);
      } else {
        const index = this.tiposDeficiencias.indexOf(2);
        if (index !== -1) {
          this.tiposDeficiencias.splice(index, 1);
        }
      }
    },
    auditivaSet() {
      if (this.deficienciaAuditiva) {
        this.tiposDeficiencias.push(1);
      } else {
        const index = this.tiposDeficiencias.indexOf(1);
        if (index !== -1) {
          this.tiposDeficiencias.splice(index, 1);
        }
      }
    },
    visualSet() {
      if (this.deficienciaVisual) {
        this.tiposDeficiencias.push(5);
      } else {
        const index = this.tiposDeficiencias.indexOf(5);
        if (index !== -1) {
          this.tiposDeficiencias.splice(index, 1);
        }
      }
    },
    fisicaSet() {
      if (this.deficienciaFisica) {
        this.tiposDeficiencias.push(6);
      } else {
        const index = this.tiposDeficiencias.indexOf(6);
        if (index !== -1) {
          this.tiposDeficiencias.splice(index, 1);
        }
      }
    },
    tiposDeficienciasSet(value) {
      this.fichaSaude.tiposDeficiencias = value;
    },
  },
};
</script>
