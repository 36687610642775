<template>
  <div>
    <modal
      :exibir="exibir"
      :titulo="`Contatos de ${form.nome}`"
      :campos="$refs"
      @fechar="fecharModal"
      id="modalContatoVinculo"
      :tamanho="'lg'"
    >
      <!-- Texto de explicação ao usuário da funcionalidade do modal -->
      <div class="my-4">
        <p>
          Ao editar os contatos de uma pessoa neste modal, é automaticamente
          atualizado os dados na ficha da pessoa escolhida, não sendo necessário
          ir até o perfil dela para editar.
        </p>
      </div>

      <!-- Tabela para exibir os contatos -->
      <tabela
        v-if="!formulario.exibir"
        :contatos="form.contatos"
        @refs="getRefs"
        @excluir="confirmarExclusao"
        @editar="editar"
      />

      <!-- Formulário para criação de contato -->
      <template v-if="formulario.exibir">
        <form-contato
          :form="formulario.model"
          :getDados="getDados"
          :descricao="formulario.descricao"
          :desabilitar="formulario.desabilitar"
          @mudancaTipoContato="mudancaTipoContato"
          @refs="getRefs"
        />
      </template>

      <!-- Footer do modal -->
      <template v-slot:modal-footer>
        <template v-if="!footerModal">
          <div class="d-flex space-between w-100">
            <b-button variant="secondary" @click="fecharModal">{{
              $t('GERAL.FECHAR')
            }}</b-button>
            <b-button variant="primary" @click="criarContato"
              >Criar novo contato</b-button
            >
          </div>
        </template>

        <!-- footer para formulario -->
        <template v-else>
          <div class="d-flex space-between w-100">
            <b-button variant="secondary" @click="cancelarCriarContato">{{
              $t('GERAL.CANCELAR')
            }}</b-button>
            <b-button variant="primary" @click="salvarOuAtualizar"
              >Salvar contato</b-button
            >
          </div>
        </template>
      </template>
    </modal>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import PessoaService from '@/common/services/pessoa/pessoa.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

// Components:
import Modal from '@/components/modal/Modal.vue';
import Tabela from './Tabela.vue';
import FormContato from './Form.vue';

export default {
  components: {
    Modal,
    Tabela,
    FormContato,
  },
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  mounted() {
    this.getTipoContato();
  },
  data() {
    return {
      ehEditar: false,
      dadosEditar: {},
      getDados: {
        tipoContato: [],
        ehContatoPrincipal: false,
      },
      formulario: {
        refs: [],
        exibir: false,
        model: {},
        desabilitar: {
          tipoContatoDescricao: true,
        },
        descricao: {
          placeholder: '',
          placeholderCustomizado: [
            { id: 232, msg: 'Digite um endereço web' },
            { id: 246, msg: 'Digite um telefone residencial' },
            { id: 247, msg: 'Digite um telefone comercial' },
            { id: 248, msg: 'Digite um telefone celular' },
            { id: 249, msg: 'Digite um endereço de email' },
            { id: 250, msg: 'Digite um endereço de fax' },
          ],
        },
      },
    };
  },
  watch: {
    exibir: 'verificaPrincipal',
    'formulario.model.tipoContatoId': [
      'habilitarDescricao',
      'atribuiDescricao',
    ],
  },
  computed: {
    footerModal() {
      return this.formulario.exibir;
    },
  },
  methods: {
    mudancaTipoContato() {
      this.formulario.model.descricao = '';
    },
    // FUNÇÕES PARA OBTER DADOS:
    getTipoContato() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-contatos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.getDados.tipoContato = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES CRUD:
    salvarOuAtualizar() {
      if (this.ehEditar) {
        this.atualizar();
      } else {
        this.salvarContato();
      }
    },
    criarContato() {
      this.formulario.desabilitar.tipoContatoDescricao = true;
      this.formulario.exibir = true;
      this.ehEditar = false;
    },
    cancelarCriarContato() {
      this.formulario.model = [];
      this.formulario.exibir = false;
    },
    atualizar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      PessoaService.atualizarContato({
        ...this.formulario.model,
        tipoContato: this.formulario.model.tipoContatoId,
        pessoaId: this.form.id,
        id: this.dadosEditar.id,
      })
        .then(({ data }) => {
          this.formulario.model = data.data.contatos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    editar(editar) {
      this.formulario.desabilitar.tipoContatoDescricao = true;
      this.formulario.exibir = true;
      this.dadosEditar = editar;
      this.ehEditar = true;
      this.formulario.model = this.dadosEditar;
    },
    salvarContato() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      PessoaService.criarContato({
        ...this.formulario.model,
        tipoContato: this.formulario.model.tipoContatoId,
        entidadeId: this.form.id,
        id: this.dadosEditar.id,
      })
        .then(({ data }) => {
          this.form.contatos = data.data.contatos;
          // limpando o formulário:
          this.formulario.model = [];
          // fechando o formulário:
          this.formulario.exibir = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    async confirmarExclusao(contato) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        this.$t('PESSOAS.CONFIRMAR_EXCLUIR_CONTATO')
      );
      if (respostaExclusao.isConfirmed) {
        if (!contato.id) {
          this.form.contatos.splice(contato.index, 1);
          return;
        }
        this.excluirContato(contato);
      }
    },
    async excluirContato(contato) {
      this.$store.dispatch(START_LOADING);
      PessoaService.removerContato(contato.id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('PESSOAS.CONTATO_EXCLUIDO')
          );
          // atualizando a tabela:
          let novaLista = [];
          this.form.contatos.forEach((element) => {
            if (element.id != contato.id) {
              novaLista.push(element);
            }
          });
          this.form.contatos = novaLista;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },

    // FUNÇÕES AUXILIARES:
    habilitarDescricao(tipoContato) {
      // procura a descrição:
      this.formulario.descricao.placeholderCustomizado.forEach((x) => {
        if (x.id == tipoContato) {
          // habilita o campo de descrição:
          this.formulario.desabilitar.tipoContatoDescricao = false;
          // troca o placeholder do input:
          this.formulario.descricao.placeholder = x.msg;
        }
      });
    },
    atribuiDescricao() {
      let tipoContatoDescricao = this.getDados.tipoContato.find(
        (item) => item.id === this.formulario.model.tipoContatoId
      );
      if (tipoContatoDescricao) {
        this.formulario.model.tipoContatoDescricao =
          tipoContatoDescricao.descricao;
      }
    },
    verificaPrincipal() {
      let filtro = this.form.contatos.filter((item) => item.principal === true);
      if (filtro.length) {
        this.formulario.model.principal = false;
        this.getDados.ehContatoPrincipal = true;
      } else {
        this.getDados.ehContatoPrincipal = false;
      }
    },
    // FUNÇÕES DE VALIDAÇÃO:
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    getRefs(refs) {
      this.refs = refs;
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.formulario.exibir = false;
      this.$emit('fechar');
    },
  },
};
</script>
