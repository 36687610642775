<template>
  <div class="mt-4">
    <h4>
      {{ $t('FICHA_SAUDE.MEDICACOES') }}
    </h4>
    <b-row>
      <b-col class="col-12">
        <input-checkbox
          ref="fichaSaude.medicamentoContinuo"
          v-model="fichaSaude.medicamentoContinuo"
          class="mt-1"
          :label="$t('FICHA_SAUDE.MEDICACOES_CONFIRMACAO')"
          :disabled="apenasVisualizar"
        />
      </b-col>
      <b-col>
        <input-text
          ref="fichaSaude.nomeMedicamento"
          v-model="fichaSaude.nomeMedicamento"
          :label="$t('FICHA_SAUDE.MEDICACOES_QUAIS')"
          :required="fichaSaude.medicamentoContinuo"
          :disabled="!fichaSaude.medicamentoContinuo || apenasVisualizar"
        />
      </b-col>
      <b-col>
        <input-text
          ref="fichaSaude.motivoMedicamento"
          v-model="fichaSaude.motivoMedicamento"
          :label="$t('FICHA_SAUDE.MEDICACOES_MOTIVO')"
          :required="fichaSaude.medicamentoContinuo"
          :disabled="!fichaSaude.medicamentoContinuo || apenasVisualizar"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import helpers from '@/common/utils/helpers';
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import InputText from '@/components/inputs/InputText.vue';
export default {
  props: ['fichaSaude', 'apenasVisualizar'],
  components: {
    InputCheckbox,
    InputText,
  },
  methods: {
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
  mounted() {
    this.$emit('atualizarRefs', this.$refs);
  },
};
</script>
