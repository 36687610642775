<template>
  <div>
    <b-row>
      <b-col class="d-flex">
        <input-checkbox
          v-model="checked"
          ref="confirmacao"
          required
          :disabled="apenasVisualizar"
        />
        <p>{{ $t('FICHA_SAUDE.DECLARAR_VERDADEIRO') }}</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import helpers from '@/common/utils/helpers';

export default {
  components: { InputCheckbox },
  props: {
    form: { type: Object, default: Object },
    apenasVisualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
  mounted() {
    this.$emit('atualizarRefs', this.$refs);
  },
};
</script>
