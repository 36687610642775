<template>
  <b-table
    :empty-text="$t('ACADEMICO_MATRICULAS.NENHUM_CONTRATO_REGISTRADO')"
    head-variant="light"
    :items="items"
    :fields="fields"
    show-empty
    responsive
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item
          @click="emitir(item.item)"
        >
          {{ $t('ACADEMICO_MATRICULAS.IMPRIMIR_BOLETIM_NOTAS_PARCIAIS') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'tipoContratoDescricao',
          label: 'Contrato',
          sortable: true,
        },
        {
          key: 'cursoNome',
          label: 'Curso',
          sortable: true,
        },
        {
          key: 'grupoTurmaDescricao',
          label: 'Turma',
          sortable: true,
        },
        {
          key: 'anoLetivoDescricao',
          label: this.$t('GERAL.ANO_LETIVO'),
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Mais ações',
        },
      ],
      selecionados: [],
    };
  },
  methods: {
    emitir(item) {
      this.$emit('imprimir-contrato', item);
    },
  },
};
</script>
