<template>
  <b-table
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    :items="items"
    :fields="fields"
    show-empty
    responsive
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-button
        v-if="!visualizar"
        variant="secondary"
        v-b-tooltip.hover
        :title="$t('ACADEMICO_MATRICULAS.REMOVER_DESCONTO')"
        @click="confirmarExclusao(item.item)"
      >
        {{ $t('GERAL.REMOVER') }}
      </b-button>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

export default {
  props: {
    items: { type: Array, default: Array },
    visualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      fields: [
        {
          key: 'descricao',
          label: this.$t('ACADEMICO_MATRICULAS.NOME'),
          sortable: true,
        },
        {
          key: 'tipoDescontoDescricao',
          label: this.$t('ACADEMICO_MATRICULAS.DESCONTO'),
          sortable: true,
        },
        {
          key: 'percentual',
          label: this.$t('ACADEMICO_MATRICULAS.PERCENTUAL'),
          sortable: true,
        },
        {
          key: 'valor',
          label: this.$t('ACADEMICO_MATRICULAS.VALOR'),
          sortable: true,
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
        {
          key: 'acoes',
          label: this.$t('ACADEMICO_MATRICULAS.ACOES'),
        },
      ],
    };
  },
  methods: {
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('GERAL.CONFIRMAR_EXCLUSAO_ITEM').formatUnicorn({
          item: `${item.descricao}`,
        })
      );
      respostaExclusao.isConfirmed ? this.excluir(item.id) : {};
    },
    excluir(id) {
      this.$emit('excluir', id);
    },
  },
};
</script>
