<template>
  <div class="row">
    <div class="col">
      <cabecalho title="ACADEMICO_MATRICULAS.OBSERVACOES" title_type="body" />
    </div>
    <div class="col-12">
      <input-text-area
        ref="observacoes"
        v-model="form.observacao"
        :maxLength="1000"
        :label="$t('ACADEMICO_MATRICULAS.OBSERVACOES_LABEL')"
        :disabled="visualizar"
      />
    </div>
    <div class="col-12" v-if="form.id && !form.codigoMatricula">
      <b-button variant="primary" @click="atualizarForm">{{
        $t('GERAL.ATUALIZAR')
      }}</b-button>
    </div>
  </div>
</template>
<script>
// Components:
import { Cabecalho } from '@/components/headers/index';
import { InputTextArea } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
    visualizar: { type: Boolean, default: false },
  },
  components: {
    Cabecalho,
    InputTextArea,
  },
  methods: {
    atualizarForm() {
      this.$emit('atualizar-form');
    },
  },
};
</script>
