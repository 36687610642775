<template>
  <div>
    <cabecalho title="ACADEMICO_MATRICULAS.DADOS_ALUNO" title_type="body" />

    <p><b>Código Matrícula:</b> {{ codigoMatricula }}</p>

    <div class="row">
      <div class="col-12 col-md-8">
        <input-text
          label="Nome"
          v-model="dadosAluno.nome"
          type="text"
          disabled
        />
      </div>
      <div class="col-12 col-md-4">
        <input-text
          label="Data de Nascimento"
          :value="dataNascimentoFormatada"
          type="text"
          disabled
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <input-text
          label="Identidade"
          v-model="dadosAluno.identidade"
          type="text"
          disabled
        />
      </div>
      <div class="col-12 col-md-4">
        <input-text
          label="Órgão expedidor"
          v-model="dadosAluno.orgaoExpedidor"
          type="text"
          disabled
        />
      </div>
      <div class="col-12 col-md-4">
        <input-text
          label="Data de expedição"
          v-model="dataExpedicaoFormatada"
          type="text"
          disabled
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <input-text label="CPF" v-model="dadosAluno.cpf" type="text" disabled />
      </div>
      <div class="col-12 col-md-4">
        <input-text
          label="Registro de Nascimento"
          v-model="dadosAluno.registroNascimento"
          type="text"
          disabled
        />
      </div>
      <div class="col-12 col-md-4">
        <input-text
          type="text"
          label="Nacionalidade"
          v-if="nacionalidade"
          v-model="dadosAluno.nacionalidade.nome"
          disabled
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <small>* Dados apenas para visualização.</small>
      </div>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';

// Components:
import { Cabecalho } from '@/components/headers/index';
import { InputText } from '@/components/inputs';
import helpers from '@/common/utils/helpers';

export default {
  props: {
    dadosAluno: { type: Object, default: Object },
  },
  components: {
    Cabecalho,
    InputText,
  },
  data() {
    return {
      dataNascimentoFormatada: '',
      dataExpedicaoFormatada: '',
    };
  },
  watch: {
    'dadosAluno.dataNascimento': 'formatacaoDataTexto',
    'dadosAluno.dataExpedicao': 'formatacaoDataTexto',
  },
  computed: {
    nacionalidade() {
      return this.dadosAluno.length !== 0 && this.dadosAluno.nacionalidade
        ? true
        : false;
    },
    codigoMatricula() {
      return this.dadosAluno.length !== 0 && this.dadosAluno.matricula
        ? this.dadosAluno.matricula.codigoMatricula
        : 'Código não encontrado';
    },
  },
  methods: {
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },

    validacaoDaTexto(data) {
      const dataNula = '0001-01-01T00:00:00';
      return data === dataNula ? '' : helpers.formatarDataBr(data);
    },
    formatacaoDataTexto() {
      this.dataNascimentoFormatada = this.validacaoDaTexto(
        this.dadosAluno.dataNascimento
      );
      this.dataExpedicaoFormatada = this.validacaoDaTexto(
        this.dadosAluno.dataExpedicao
      );
    },
  },
};
</script>
