<template>
  <div class="mt-4">
    <cabecalho title="ACADEMICO_MATRICULAS.DESCONTO" title_type="body" />

    <div class="row mb-3" v-if="!visualizar">
      <div class="col-12 col-md-6">
        <input-select-search
          label="ACADEMICO_MATRICULAS.SELECIONAR_DESCONTO"
          :options="listaDescontos"
          v-model="selecionado.descontoId"
        />
      </div>
      <div class="col-12 col-md-3 col-lg-2 my-auto">
        <b-button
          @click="addDesconto"
          variant="primary"
          class="w-100 btn-bootstrap-ajuste-mt "
        >
          {{ $t('ACADEMICO_MATRICULAS.ADICIONAR_DESCONTO') }}
        </b-button>
      </div>
    </div>

    <tabela-descontos
      :visualizar="visualizar"
      v-if="descontosTabela"
      :items="form.descontos"
      @excluir="excluir"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import MatriculaService from '@/common/services/matriculas/matriculas.service';
import Matricula from '@/common/services/matriculas/matriculas.service';

// Components:
import { Cabecalho } from '@/components/headers/index';
import { InputSelectSearch } from '@/components/inputs';
import TabelaDescontos from '@/views/academico/matriculas/components/descontos/Tabela';

export default {
  props: ['form', 'visualizar'],
  components: {
    Cabecalho,
    InputSelectSearch,
    TabelaDescontos,
  },
  data() {
    return {
      matriculaId: undefined,
      descontos: [],
      descontosManipulacao: [],
      selecionado: {
        descontoId: '',
      },
    };
  },
  mounted() {
    if (this.$route.name !== 'editar-matricula') {
      this.matriculaId = undefined;
    }
    this.getDescontos();
  },
  computed: {
    'this.form.descontos'() {
      return this.form.descontos;
    },
    listaDescontos() {
      return this.descontosManipulacao;
    },
    descontosTabela() {
      let resultado;
      if (this.form.descontos !== undefined) {
        resultado =
          this.form.descontos.length > 0 ? this.form.descontos : false;
      }
      return resultado;
    },
  },
  methods: {
    getDescontos() {
      this.$store.dispatch(START_LOADING);
      Matricula.getDescontos()
        .then(({ data }) => {
          // salvando o resultado:
          this.descontos = data;
          this.descontos = this.descontos.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          // salvando em lista auxiliar para manipular os dados:
          this.descontosManipulacao = this.descontos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    addDesconto() {
      // caso seja uma matrícula nova:
      if (!this.form.descontos) {
        this.form.descontos = [];
      }

      // retorna caso não tenha sido selecionado nenhum:
      if (!this.selecionado.descontoId.length) {
        return;
      }
      // adicionando o item na tabela:
      this.descontos.forEach((element) => {
        if (element.id == this.selecionado.descontoId) {
          this.form.descontos.push(element);
        }
      });
      if (this.matriculaId) {
        this.$store.dispatch(START_LOADING);
        const id = this.selecionado.descontoId;
        MatriculaService.adicionarDesconto({
          id,
          matriculaId: this.matriculaId,
        })
          .then(({ data }) => {
            this.form.filiacoes = data.data.matricula.filiacoes;
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
      // removendo o item da lista de descontos:
      this.descontosManipulacao = this.descontosManipulacao.filter(
        (item) => item.id !== this.selecionado.descontoId
      );
      this.selecionado.descontoId = '';
      // enviando resposta ao componente pai:
      this.$emit('desconto', this.form.descontos);
    },
    removerDesconto(id) {
      this.$store.dispatch(START_LOADING);
      MatriculaService.removerDesconto({ id, matriculaId: this.matriculaId })
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.form.descontos = this.form.descontos.filter(
            (item) => item.id !== id
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    excluir(id) {
      // removendo o item da tabela
      this.form.descontos = this.form.descontos.filter(
        (item) => item.id !== id
      );

      if (this.matriculaId) {
        this.removerDesconto(id);
      }
      // adicionando o item da lista de descontos:
      this.descontos.forEach((element) => {
        if (element.id == id) {
          this.descontosManipulacao.push(element);
        }
      });
      // enviando resposta ao componente pai:
      this.$emit('desconto', this.form.descontos);
    },
  },
};
</script>
