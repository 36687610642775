<template>
  <b-form class="my-3">
    <input-text
      ref="nome"
      v-model="form.nome"
      :label="$t(`PESSOAS.NOME`)"
      placeholder="Digite um nome"
    />

    <input-select-search
      ref="tipoContatoId"
      :options="getDados.tipoContato"
      v-model="form.tipoContatoId"
      :label="$t('PESSOAS.TIPO_CONTATO')"
      placeholder="Selecione ou digite"
      required
      @input="mudancaTipoContato"
    />

    <input-text
      ref="descricao"
      v-model="form.descricao"
      :label="$t('PESSOAS.DESCRICAO')"
      :contactType="form.tipoContatoDescricao"
      :placeholder="descricao.placeholder"
      :disabled="desabilitar.tipoContatoDescricao"
      required
    />
    
    <b-form-checkbox
      v-model="form.principal"
      :value="true"
      :unchecked-value="false"
      :disabled="getDados.ehContatoPrincipal"
    >
      <label v-if="!this.getDados.ehContatoPrincipal">{{
        $t('PESSOAS.CONTATO_PRINCIPAL')
      }}</label>
      <label v-else>Contato principal já foi registrado</label>
    </b-form-checkbox>
  </b-form>
</template>
<script>
import { InputText, InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
    getDados: { type: Object, default: Object },
    descricao: { type: Object, default: Object },
    desabilitar: { type: Object, default: Object },
  },
  components: {
    InputText,
    InputSelectSearch,
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
  methods: {
    mudancaTipoContato() {
      this.$emit('mudancaTipoContato');
    },
  },
};
</script>
