<template>
  <div class="row">
    <div class="col-12">
      <cabecalho title="ACADEMICO_MATRICULAS.DADOS_ALUNO" title_type="body" />
    </div>
    <div class="col-12 col-md-4">
      <InputSelectTyping
        ref="form.pessoaId"
        :options="pessoasFiltradas"
        v-model="form.pessoaId"
        :label="$t('ACADEMICO_MATRICULAS.ALUNO')"
        placeholder="Selecione ou digite"
        required
        @pesquisa-nome="pesquisaPorNome"
        v-if="!form.codigoMatricula"
        :loading="loadingPessoas"
      />
      <input-text
        v-else
        :label="$t('ACADEMICO_MATRICULAS.ALUNO')"
        :value="form.nome"
        disabled
      />
    </div>
  </div>
</template>
<script>
// Components:
import { Cabecalho } from '@/components/headers/index';
import {
  // InputSelectMultiple,
  InputSelectTyping,
  InputText,
} from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
    pessoasFiltradas: { type: Array, default: Array },
    visualizar: { type: Boolean, default: false },
    loadingPessoas: { type: Boolean, default: false },
  },
  components: {
    Cabecalho,
    InputSelectTyping,
    InputText,
  },
  watch: {
    loadingPessoas: {
      handler(value) {
        if (
          !value &&
          this.$route.params.id &&
          this.pessoasFiltradas.length > 0
        ) {
          this.form.pessoaId = this.$route.params.id;
        }
      },
      immediate: true,
    },
  },
  methods: {
    pesquisaPorNome(nome) {
      this.$emit('pesquisa-por-nome', nome);
    },
  },
};
</script>
