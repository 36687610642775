<template>
  <div class="mt-4">
    <h4>
      {{ $t('FICHA_SAUDE.OBSERVACOES') }}
    </h4>
    <b-row class="mt-4">
      <b-col>
        <InputTextArea
          ref="fichaSaude.observacoes"
          v-model="fichaSaude.observacoes"
          :label="$t('FICHA_SAUDE.OBSERVACOES_OUTRAS')"
          :disabled="apenasVisualizar"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import InputTextArea from '@/components/inputs/InputTextArea.vue';
export default {
  props: ['fichaSaude', 'apenasVisualizar'],
  components: {
    InputTextArea,
  },
  mounted() {
    this.$emit('atualizarRefs', this.$refs);
  },
};
</script>
