<template>
  <div>
    <div v-if="apenasVisualizar">
      <cabecalho title="FICHA_SAUDE.FICHA_SAUDE" title_type="body" />
    </div>
    <p v-if="!apenasVisualizar">{{ $t('FICHA_SAUDE.SOBRE') }}</p>
    <plano-saude
      :fichaSaude="fichaSaude"
      @atualizarRefs="atualizarRefs"
      :apenasVisualizar="apenasVisualizar"
    />
    <intolerancias
      :fichaSaude="fichaSaude"
      @atualizarRefs="atualizarRefs"
      :apenasVisualizar="apenasVisualizar"
    />
    <medicacoes
      :fichaSaude="fichaSaude"
      @atualizarRefs="atualizarRefs"
      ref="medicacoesRef"
      :apenasVisualizar="apenasVisualizar"
    />
    <alergias
      :fichaSaude="fichaSaude"
      @atualizarRefs="atualizarRefs"
      ref="alergiasRef"
      :apenasVisualizar="apenasVisualizar"
    />
    <deficiencias
      :fichaSaude="fichaSaude"
      @atualizarRefs="atualizarRefs"
      ref="deficienciasRef"
      :apenasVisualizar="apenasVisualizar"
    />
    <observacoes
      :fichaSaude="fichaSaude"
      @atualizarRefs="atualizarRefs"
      :apenasVisualizar="apenasVisualizar"
    />
    <confirmacao-form
      v-if="!apenasVisualizar"
      @confirmar="fichaSaude"
      @atualizarRefs="atualizarRefs"
      ref="confirmarInfoRef"
      :apenasVisualizar="apenasVisualizar"
    />
  </div>
</template>
<script>
import breadcrumb from '@/common/utils/breadcrumb';

import { Cabecalho } from '@/components/headers/index';
import PlanoSaude from './PlanoSaude';
import Intolerancias from './Intolerancias';
import Medicacoes from './Medicacoes';
import Alergias from './Alergias';
import Deficiencias from './Deficiencias';
import Observacoes from './Observacoes';
import ConfirmacaoForm from './ConfirmacaoForm';
export default {
  components: {
    PlanoSaude,
    Intolerancias,
    Medicacoes,
    Alergias,
    Deficiencias,
    Observacoes,
    ConfirmacaoForm,
    Cabecalho,
  },
  props: {
    fichaSaude: { type: Object, default: Object },
    apenasVisualizar: { type: Boolean, default: false },
  },
  mounted() {
    breadcrumb.definir(this.$store, [{ titulo: 'Ficha de Saúde' }]);
  },
  data() {
    return {
      refs: '',
    };
  },
  watch: {
    fichaSaude: 'atualizar',
  },
  methods: {
    validarFormulario() {
      let validationArray = [];
      validationArray.push(this.$refs.medicacoesRef.validarFormulario());
      validationArray.push(this.$refs.alergiasRef.validarFormulario());
      validationArray.push(this.$refs.deficienciasRef.validarFormulario());
      validationArray.push(this.$refs.confirmarInfoRef.validarFormulario());
      return validationArray.filter((elem) => elem == false).length == 0;
    },

    confirmacao(checkboxValue) {
      this.$emit('confirmar', checkboxValue);
    },

    atualizar(value) {
      this.$emit('fichasaude', value);
    },

    atualizarRefs(refs) {
      this.$emit('refs', refs);
    },
  },
};
</script>
