<template>
  <div class="mt-4">
    <h4>
      {{ $t('FICHA_SAUDE.ALERGIAS') }}
    </h4>
    <b-row>
      <b-col class="col-12">
        <input-checkbox
          ref="fichaSaude.alergia"
          v-model="fichaSaude.alergia"
          :label="$t('FICHA_SAUDE.ALERGIA_CONFIRMACAO')"
          :disabled="apenasVisualizar"
        />
      </b-col>
      <b-col>
        <input-text
          ref="fichaSaude.especificaoAlergia"
          :required="fichaSaude.alergia"
          v-model="fichaSaude.especificaoAlergia"
          :label="$t('FICHA_SAUDE.ALERGIA_QUAIS')"
          :disabled="!fichaSaude.alergia || apenasVisualizar"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import InputText from '@/components/inputs/InputText.vue';
import helpers from '@/common/utils/helpers';

export default {
  props: ['fichaSaude', 'apenasVisualizar'],
  components: {
    InputCheckbox,
    InputText,
  },
  methods: {
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
  mounted() {
    this.$emit('atualizarRefs', this.$refs);
  },
};
</script>
