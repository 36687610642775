<template>
  <div class="mt-4">
    <h4>
      {{ $t('FICHA_SAUDE.OBSERVACOES') }}
    </h4>
    <b-row>
      <input-text
        class="col-6"
        ref="fichaSaude.planoDeSaude"
        v-model="fichaSaude.planoDeSaude"
        :label="$t('FICHA_SAUDE.PLANO_SAUDE')"
        :placeholder="$t('FICHA_SAUDE.PLANO_SAUDE_PLACEHOLDER')"
        :disabled="apenasVisualizar"
      />
      <input-text
        class="col-6"
        ref="fichaSaude.numeroCarteirinha"
        v-model="fichaSaude.numeroCarteirinha"
        :label="$t('FICHA_SAUDE.PLANO_SAUDE_NUMERO_CARTEIRINHA')"
        :placeholder="
          $t('FICHA_SAUDE.PLANO_SAUDE_NUMERO_CARTEIRINHA_PLACEHOLDER')
        "
        :disabled="apenasVisualizar"
      />
    </b-row>
  </div>
</template>
<script>
import InputText from '@/components/inputs/InputText.vue';
export default {
  props: ['fichaSaude', 'apenasVisualizar'],
  components: {
    InputText,
  },
  mounted() {
    this.$emit('atualizarRefs', this.$refs);
  },
};
</script>
