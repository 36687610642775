<template>
  <div class="mt-4">
    <cabecalho
      title="ACADEMICO_MATRICULAS.CONTRATOS"
      description="ACADEMICO_MATRICULAS.MATRICULA_CONTRATO_EXPLICACAO"
      title_type="body"
    />
    <tabela-academico v-if="contrato" :items="contrato" @imprimir-contrato="baixarContrato" />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import pdf from '@/common/utils/pdf';

// Services:
import CursoService from '@/common/services/academico/curso.service';
import ContratoService from '@/common/services/contratos/contratos.service';

// Components:
import { Cabecalho } from '@/components/headers/index';
import TabelaAcademico from '@/views/academico/matriculas/components/contratos/Tabela';

export default {
  name: 'Contratos',
  props: ['contratos'],
  components: {
    Cabecalho,
    TabelaAcademico,
  },
  data() {
    return {
      selecionado: {
        opcoes: [],
        contratos: [],
      },
      contrato: [],
    };
  },
  watch: {
    contratos: 'getNomes',
  },
  methods: {
    getNomes() {
      this.contratos.forEach((element) => {
        CursoService.obterPorId(element.cursoId)
          .then(({ data }) => {
            element.cursoNome = data.descricao;
            this.contrato.push(element);
          })
          .catch(() => {
            element.cursoNome = 'Não encontrado';
            this.contrato.push(element);
          })
          .finally(() => {});
      });
    },
    baixarContrato({ id }) {
      this.$store.dispatch(START_LOADING);

      ContratoService.baixarPDF(id)
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
