<template>
  <div class="row">
    <div class="col-12">
      <cabecalho
        title="ACADEMICO_MATRICULAS.DADOS_RESPONSAVEIS"
        title_type="body"
      />
    </div>
    <div class="col-12">
      <b-table
        :empty-text="$t('GERAL.NENHUM_REGISTRO')"
        head-variant="light"
        :fields="filtrarFields"
        :items="vinculos"
        show-empty
        responsive
        striped
        hover
      >
        <template #cell(contatos)="item" v-if="!visualizar">
          <b-button variant="primary" @click="contatos(item.item)">
            Contatos
          </b-button>
        </template>
        <template #cell(acoes)="item" v-if="!visualizar">
          <b-dropdown variant="outline-default">
            <b-dropdown-item @click="excluir(item)">
              {{ $t('GERAL.EXCLUIR') }}
            </b-dropdown-item>
            <b-dropdown-item @click="editar(item)">
              {{ $t('GERAL.EDITAR') }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { Cabecalho } from '@/components/headers/index';

export default {
  components: {
    Cabecalho,
  },
  props: {
    vinculos: { type: Array, default: Array },
    visualizar: { type: Boolean, default: false },
  },
  computed: {
    filtrarFields() {
      if (this.visualizar) {
        return this.fields.filter(
          (field) => field.key !== 'contatos' && field.key !== 'acoes'
        );
      } else {
        return this.fields;
      }
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'codigo',
          label: 'Código pessoa',
          sortable: true,
        },
        {
          key: 'nome',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'tipoFiliacaoDescricao',
          label: 'Filiação',
          sortable: true,
        },
        {
          key: 'tipoResponsavelDescricao',
          label: 'Responsável por',
          sortable: true,
        },
        {
          key: 'contatos',
          label: 'Contatos',
        },
        {
          key: 'acoes',
          label: 'Mais ações',
        },
      ],
    };
  },
  methods: {
    contatos(item) {
      this.$emit('contatos', item);
    },
    excluir(item) {
      this.$emit('excluir', item);
    },
    editar(item) {
      this.$emit('editar', item);
    },
  },
};
</script>
