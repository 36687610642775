<template>
  <modal
    id="ModalEnderecoAluno"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <b-row class="flex-column flex-sm-row w-100">
      <input-mask
        v-model="formEndereco.cep"
        :label="$t('PESSOAS.CEP')"
        placeholder="Digite apenas números"
        :mask="['#####-###']"
        class="col-md-6"
        type="text"
      />
      <input-select-search
        class="col-md-6"
        ref="paisId"
        :label="$t(`PESSOAS.PAIS`)"
        :options="gets.listaPais"
        v-model="formEndereco.paisId"
        :isDisabled="disabled.pais"
      />
      <input-select-search
        class="col-md-6"
        ref="estadoId"
        :label="$t(`PESSOAS.ESTADO`)"
        placeholder="Digite ou selecione"
        :options="gets.listaEstado"
        v-model="formEndereco.estadoId"
        required
      />
      <input-select-search
        class="col-md-6"
        ref="municipioId"
        :label="$t(`PESSOAS.MUNICIPIO`)"
        placeholder="Digite ou selecione"
        :options="gets.listaMunicipio"
        v-model="formEndereco.municipioId"
        required
      />
      <input-text
        class="col-md-6"
        ref="bairro"
        :label="$t('PESSOAS.BAIRRO')"
        placeholder="Digite aqui"
        v-model="formEndereco.bairro"
        required
      />
      <input-text
        class="col-md-6"
        ref="logradouro"
        :label="$t('PESSOAS.LOGRADOURO')"
        placeholder="Digite aqui"
        v-model="formEndereco.logradouro"
        required
      />
      <input-text
        class="col-md-6"
        ref="numero"
        :label="$t('PESSOAS.NUMERO')"
        placeholder="Digite aqui"
        v-model="formEndereco.numero"
        required
      />
      <input-text
        class="col-md-6"
        :label="$t('PESSOAS.COMPLEMENTO')"
        placeholder="Campo opcional"
        v-model="formEndereco.complemento"
      />
      <input-select-search
        class="col-md-6"
        ref="tipoEndereco"
        :label="$t(`PESSOAS.TIPO_ENDERECO`)"
        placeholder="Digite ou selecione"
        :options="gets.listaTipoEndereco"
        @input="obterDescricaoTipoEndereco"
        v-model="formEndereco.tipoEndereco"
        required
      />

      <b-form-group class="col-sm-12">
        <b-form-checkbox
          v-model="formEndereco.principal"
          :value="true"
          :unchecked-value="false"
        >
          <label>{{ $t('PESSOAS.ENDERECO_PRINCIPAL') }}</label>
        </b-form-checkbox>
      </b-form-group>
    </b-row>

    <template #modal-footer>
      <b-button variant="secondary" @click="fecharModal">
        {{ $t('GERAL.FECHAR') }}
      </b-button>
      <b-button variant="primary" @click="salvar"
        >{{ $t('GERAL.SALVAR') }}
      </b-button>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import { validationMixin } from 'vuelidate';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

// Services:
import Endereco from '@/common/models/pessoas/Endereco.js';
import EnderecoService from '@/common/services/endereco/endereco.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import PessoaService from '@/common/services/pessoa/pessoa.service';

// Components:
import Modal from '@/components/modal/Modal.vue';
import { InputSelectSearch, InputText, InputMask } from '@/components/inputs';

export default {
  mixins: [validationMixin],
  components: {
    Modal,
    InputSelectSearch,
    InputText,
    InputMask,
  },
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
    endereco: { type: Object, default: Object },
  },
  data() {
    return {
      formEndereco: [{ tipoEnderecoDescricao: '' }],
      dadosPesquisaCEP: [],
      idQuerry: this.$route.params.id,
      gets: {
        brasil: [],
        listaPais: [],
        listaEstado: [],
        listaMunicipio: [],
        listaTipoEndereco: [],
      },
      disabled: {
        pais: true,
      },
    };
  },
  mounted() {
    this.getPaises();
    this.getTipoEndereco();
  },
  computed: {
    titulo() {
      return !this.endereco.id
        ? this.$t('GERAL.CADASTRAR')
        : this.$t('GERAL.EDITAR');
    },
  },
  watch: {
    exibir: 'verificaNovoOuEditar',
    'formEndereco.estadoId': 'getMunicipios',
    'formEndereco.cep': 'buscaDadosCEP',
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    obterDescricaoTipoEndereco() {
      const resultado = this.gets.listaTipoEndereco.filter(
        ({ id }) => id === this.formEndereco.tipoEndereco
      );
      this.formEndereco.tipoEnderecoDescricao = resultado[0]?.descricao;
    },
    verificaNovoOuEditar() {
      this.endereco.id
        ? (this.formEndereco = this.endereco)
        : (this.formEndereco = new Endereco({}));
      this.getPaises();
      this.getEstados();
      this.getMunicipios();
    },
    getTipoEndereco() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-enderecos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.gets.listaTipoEndereco = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getPaises() {
      this.$store.dispatch(START_LOADING);
      EnderecoService.buscarNacionalidades()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.nome,
          }));
          this.gets.listaPais = data;
          this.getBrasil(); // atualmente fazemos busca de estado BR então salvo o BR direto no this.gets.brasil
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getBrasil() {
      this.gets.listaPais.forEach((element) => {
        if (element.nome == 'Brasil') {
          this.formEndereco.paisId = element.id;
          return (this.gets.brasil = element);
        }
      });
    },
    getEstados() {
      this.$store.dispatch(START_LOADING);
      EnderecoService.buscarEstados(this.gets.brasil.id)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.nome,
          }));
          this.gets.listaEstado = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getMunicipios() {
      this.$store.dispatch(START_LOADING);
      if (this.formEndereco.estadoId)
        EnderecoService.buscarMunicipios(this.formEndereco.estadoId)
          .then(({ data }) => {
            data = data.map((row) => ({
              ...row,
              value: row.id,
              text: row.nome,
            }));
            this.gets.listaMunicipio = data;
            if (this.dadosPesquisaCEP) {
              this.encontrarMunicipio();
            }
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
    },
    encontrarMunicipio() {
      this.gets.listaMunicipio.forEach((element) => {
        if (element.nome == this.dadosPesquisaCEP.localidade) {
          this.formEndereco.municipioId = element.id;
        }
      });
    },
    buscaDadosCEP() {
      if (this.formEndereco.cep && this.formEndereco.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.formEndereco.cep}/json/`)
          .then((response) => {
            if (response.data) {
              if (response.data.erro) {
                this.formEndereco = new Endereco({});
                this.formEndereco.paisId = this.gets.brasil.id;
                this.formEndereco.cep = '';
                mensagem.erro(this.$t('CAMPOS_VALIDACAO.CEP_INEXISTENTE'));
                return;
              }

              this.dadosPesquisaCEP = response.data;
              if (this.dadosPesquisaCEP) {
                this.preenchimentoAutomatico();
              }
            }
          })
          .catch((error) => {
            this.formEndereco = new Endereco({});
            this.formEndereco.paisId = this.gets.brasil.id;
            this.formEndereco.cep = '';
            mensagem.erro(this.$t('CAMPOS_VALIDACAO.CEP_INEXISTENTE'), error);
          });
      }
    },
    preenchimentoAutomatico() {
      this.gets.listaEstado.forEach((element) => {
        if (element.sigla == this.dadosPesquisaCEP.uf) {
          this.estadoId = element.id;
          this.formEndereco.estadoId = element.id;
          // se encontrar o estado, está apto para munícipio:
          if (this.formEndereco.estadoId) {
            this.getMunicipios();
          }
        }
      });
      this.formEndereco.bairro = this.dadosPesquisaCEP.bairro;
      this.formEndereco.logradouro = this.dadosPesquisaCEP.logradouro;
    },
    verificaPrincipal() {
      this.form.enderecos.forEach((element, index) => {
        element.index = index;
        if (
          this.formEndereco.principal === true &&
          element.principal === true &&
          this.formEndereco.index !== element.index
        ) {
          element.principal = false;
        }
      });
    },
    // FUNÇÕES CRUD:
    criarEnderecoAtribuidoPessoa(endereco) {
      this.$store.dispatch(START_LOADING);
      PessoaService.criarEndereco({ ...endereco, entidadeId: this.id })
        .then(({ data }) => {
          this.form.enderecos = data.data.enderecos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    atualizarEndereco(endereco, edicaoLocal) {
      if (!edicaoLocal) {
        this.$store.dispatch(START_LOADING);
        PessoaService.atualizarEndereco({ ...endereco, entidadeId: this.id })
          .then(({ data }) => {
            this.form.enderecos = data.data.enderecos;
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
        return;
      }

      const { item, index } = endereco;
      this.form.enderecos[index] = item;
      this.form.enderecos = this.form.enderecos.filter((item) => item);
    },
    salvar() {
      if (!this.idQuerry) this.formEndereco.id = uuidv4();
      if (!this.validarFormulario()) return;

      // se for um novo endereço:
      if (!this.endereco.id) {
        this.id
          ? this.criarEnderecoUserExistente()
          : this.criarEnderecoNovoUser();
        this.fecharModal();
        return;
      }
      // se for a edição de um endereço:
      else {
        this.id
          ? this.editarEnderecoUserExistente()
          : this.editarEnderecoNovoUser();
        this.fecharModal();
        return;
      }
    },
    // FUNÇÕES PARA USER EXISTENTE:
    criarEnderecoNovoUser() {
      this.form.enderecos.push(this.formEndereco);
      this.verificaPrincipal();
    },
    editarEnderecoNovoUser() {
      this.atualizarEndereco(this.endereco, true);
      this.verificaPrincipal();
    },
    // FUNÇÕES PARA NOVO USER:
    criarEnderecoUserExistente() {
      this.criarEnderecoAtribuidoPessoa(this.formEndereco);
    },
    editarEnderecoUserExistente() {
      this.atualizarEndereco(this.formEndereco);
      this.fecharModal();
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    // FUNÇÕES AUXILIARES:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
