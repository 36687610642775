<template>
  <div class="mt-4">
    <h4>
      {{ $t('FICHA_SAUDE.INTOLERANCIAS') }}
    </h4>
    <b-row>
      <b-col class="col-3">
        <input-checkbox
          ref="fichaSaude.intoleranciaLactose"
          v-model="fichaSaude.intoleranciaLactose"
          :label="$t('FICHA_SAUDE.INTOLERANCIAS_LACTOSE')"
          :disabled="apenasVisualizar"
        />
      </b-col>
      <b-col class="col-3">
        <input-checkbox
          ref="fichaSaude.intoleranciaGluten"
          v-model="fichaSaude.intoleranciaGluten"
          :label="$t('FICHA_SAUDE.INTOLERANCIAS_GLUTEN')"
          :disabled="apenasVisualizar"
        />
      </b-col>
      <b-col class="col-3">
        <input-checkbox
          ref="fichaSaude.refluxo"
          v-model="fichaSaude.refluxo"
          :label="$t('FICHA_SAUDE.REFLUXO')"
          :disabled="apenasVisualizar"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
export default {
  props: ['fichaSaude', 'apenasVisualizar'],
  components: {
    InputCheckbox,
  },
  mounted() {
    this.$emit('atualizarRefs', this.$refs);
  },
};
</script>
