<template>
  <div class="mt-3">
    <cabecalho
      title="Filiações & Responsáveis"
      description="ACADEMICO_MATRICULAS.VINCULOS_COMPONENTE_DESCRICAO"
      title_type="body"
    />
    <b-form class="row py-4">
      <b-form-group class="col-3">
        <InputSelectTyping
          ref="pessoa.pessoaId"
          v-model="pessoa.pessoaId"
          :options="pessoasFiltradas"
          label="ACADEMICO_MATRICULAS.SELECIONE_PESSOA"
          placeholder="ACADEMICO_MATRICULAS.DIGITE_PARA_FILTRAR"
          required
          @pesquisa-nome="pesquisaPorNome"
          :loading="loadingPessoas"
        />
      </b-form-group>

      <b-form-group class="col-2">
        <input-select-search
          v-model="pessoa.tipoFiliacao"
          :options="dadosForm.tipoFiliacao"
          label="ACADEMICO_MATRICULAS.VINCULO_COM_ALUNO"
          placeholder="ACADEMICO_MATRICULAS.DIGITE_PARA_FILTRAR"
          required
          :loading="loadingTipoFiliacao"
        />
      </b-form-group>

      <b-form-group class="col-2">
        <input-select-search
          v-model="pessoa.tipoResponsavel"
          :options="dadosForm.tipoResponsavel"
          label="ACADEMICO_MATRICULAS.RESPONSAVEL_POR"
          placeholder="ACADEMICO_MATRICULAS.DIGITE_PARA_FILTRAR"
          required
          :loading="loadingTipoResponsavel"
        />
      </b-form-group>

      <b-form-group class="my-auto">
        <b-button variant="primary" @click="atualizarOuAdicionar">
          {{ modo }}
        </b-button>
      </b-form-group>

      <b-form-group class="col-2 my-auto" v-if="edicao">
        <b-button variant="primary" @click="cancelarEdicao">
          {{ 'Cancelar edição' }}
        </b-button>
      </b-form-group>
    </b-form>

    <tabela-vinculos
      :vinculos="vinculos"
      @contatos="contatos"
      @excluir="confirmarRemocao"
      @editar="editar"
    />
  </div>
</template>
<script>
// Utils:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import PessoaService from '@/common/services/pessoa/pessoa.service';
import MatriculaService from '@/common/services/matriculas/matriculas.service';

// Components:
import { Cabecalho } from '@/components/headers/index';
import TabelaVinculos from '@/views/academico/matriculas/components/vinculos/Tabela';
import { InputSelectSearch, InputSelectTyping } from '@/components/inputs';

export default {
  props: ['form', 'pessoasFiltradas', 'loadingPessoas'],
  components: {
    Cabecalho,
    TabelaVinculos,
    InputSelectSearch,
    InputSelectTyping,
  },
  mounted() {
    this.getPessoas();
    this.getTipoFiliacao();
    this.getTipoResponsavel();
  },
  data() {
    return {
      itemEdicao: {},
      edicao: false,
      loadingTipoResponsavel: false,
      loadingTipoFiliacao: false,
      matriculaId: this.$route.params.id ?? undefined,
      dadosForm: {
        pessoas: [],
        tipoResponsavel: [],
        tipoFiliacao: [],
      },
      pessoa: {
        pessoaId: '',
        pessoa: {},
        tipoFiliacao: '',
        tipoFiliacaoDescricao: '',
        tipoResponsavel: '',
        tipoResponsavelDescricao: '',
      },
    };
  },
  computed: {
    modo() {
      return this.edicao
        ? this.$t('Atualizar')
        : this.$t('ACADEMICO_MATRICULAS.ADICIONAR_PESSOA_TABELA');
    },
    vinculos() {
      if (this.form.vinculos) {
        this.form.vinculos.forEach((vinculo) => {
          this.dadosForm.pessoas.forEach((element) => {
            if (element.id == vinculo.pessoaId) {
              vinculo.nome = element.nome;
              vinculo.codigo = element.codigo;
              vinculo.cpf = element.cpf;
            }
          });
        });
      }
      return this.form.vinculos;
    },
  },
  methods: {
    pesquisaPorNome(nome) {
      this.$emit('pesquisa-por-nome', nome);
    },
    editar(dados) {
      this.pessoa = Object.assign({}, dados.item);
      this.itemEdicao = dados;
      this.edicao = true;
    },
    cancelarEdicao() {
      this.edicao = false;
      this.pessoa = {};
    },

    getPessoas() {
      this.$store.dispatch(START_LOADING);
      PessoaService.buscarTodosSemMatricula()
        .then(({ data }) => {
          this.dadosForm.pessoas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoResponsavel() {
      this.loadingTipoResponsavel = true;
      EnumeradoresService.listar('tipo-responsaveis')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
            codigo: row.codigo,
          }));
          this.dadosForm.tipoResponsavel = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingTipoResponsavel = false;
        });
    },
    getTipoFiliacao() {
      this.loadingTipoFiliacao = true;
      EnumeradoresService.listar('grau-parentesco')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.dadosForm.tipoFiliacao = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingTipoFiliacao = false;
        });
    },
    // FLUXO PARA ADICIONAR
    ajeitaPraTabela() {
      let vinculoBackEnd = {
        pessoaId: this.pessoa.pessoaId,
        tipoFiliacao: this.pessoa.tipoFiliacao,
        tipoResponsavel: this.pessoa.tipoResponsavel,
        matriculaId: this.matriculaId === '' ? undefined : this.matriculaId,
        codigo: this.pessoa.codigo,
        id: this.pessoa.Id,
      };
      this.dadosForm.tipoFiliacao.forEach((filiacao) => {
        if (vinculoBackEnd.tipoFiliacao == filiacao.id) {
          vinculoBackEnd.tipoFiliacaoDescricao = filiacao.descricao;
        }
      });
      this.dadosForm.tipoResponsavel.forEach((responsavel) => {
        if (vinculoBackEnd.tipoResponsavel == responsavel.id) {
          vinculoBackEnd.tipoResponsavelDescricao = responsavel.descricao;
        }
      });

      if (this.edicao) {
        return vinculoBackEnd;
      } else {
        this.form.vinculos.push(vinculoBackEnd);
      }
    },
    criarVinculo() {
      let vinculoBackEnd = {
        pessoaId: this.pessoa.pessoaId,
        tipoFiliacao: this.pessoa.tipoFiliacao,
        tipoResponsavel: this.pessoa.tipoResponsavel,
        matriculaId: this.matriculaId === '' ? undefined : this.matriculaId,
        id: this.pessoa.id === '' ? undefined : this.pessoa.id,
      };
      MatriculaService.criarVinculo(vinculoBackEnd)
        .then(() => {
          this.ajeitaPraTabela();
          this.limpaModel();
          this.obterVinculosPorMatricula();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    verificarExistencia(lista) {
      for (let i = 0; i < lista.length; i++) {
        if (
          lista[i].tipoResponsavel === 469 ||
          lista[i].tipoResponsavel === 188
        ) {
          return true;
        }
      }
      return false;
    },
    atualizarOuAdicionar() {
      if (this.edicao) {
        this.atualizar();
      } else {
        this.adicionar();
      }
    },
    atualizar() {
      if (this.$route.params.id) {
        this.atualizarBanco();
      } else {
        this.atualizarLocal();
      }
    },
    atualizarLocal() {
      this.pessoa = this.ajeitaPraTabela();
      this.form.vinculos.splice(this.itemEdicao.index, 1, this.pessoa);
      this.edicao = false;
      this.pessoa = {};
    },
    atualizarBanco() {
      MatriculaService.atualizarVinculo(this.pessoa)
        .then(() => {
          this.obterVinculosPorMatricula();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    obterVinculosPorMatricula() {
      MatriculaService.buscarVinculosPorMatriculaId(this.$route.params.id)
        .then(({ data }) => {
          this.form.vinculos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    adicionar() {
      if (
        this.pessoa.tipoResponsavel == 469 ||
        this.pessoa.tipoResponsavel == 188
      ) {
        const existeRestrito = this.verificarExistencia(this.form.vinculos);

        if (existeRestrito) {
          return mensagem.warning(
            'Não pode haver mais de um responsável com a resposabilidade financeira'
          );
        }
      }

      // verifica se é filiacao ou responsavel:
      if (!this.pessoa.pessoaId) {
        return mensagem.erro(
          'Selecione uma pessoa e atribua a ela uma filiação ou responsabilidade'
        );
      }
      if (this.pessoa.tipoResponsavel == 0 && this.pessoa.tipoFiliacao == 0) {
        return mensagem.erro(
          'Você não pode adicionar uma pessoa sem atribuir a ela uma filiação ou responsabilidade.'
        );
      } else {
        // se for uma matrícula nova:
        if (!this.matriculaId) {
          this.ajeitaPraTabela();
          this.limpaModel();
          return;
        } else {
          this.criarVinculo();
        }
      }
    },
    // FLUXO PARA REMOVER:
    async confirmarRemocao(item) {
      // confirmando se o user logado realmente quer excluir:
      let confirmarRemocao = await mensagem.confirmacao(
        null,
        'Você realmente quer excluir essa pessoa?'
      );
      if (confirmarRemocao.isConfirmed) {
        this.cancelarEdicao();
        // ternário para apagar caso nova matrícula ou matrícula já existente:
        !this.matriculaId
          ? this.form.vinculos.splice(item.index, 1)
          : this.removerPessoa(item.item);
      }
    },
    removerPessoa(item) {
      MatriculaService.removerVinculo(this.matriculaId, item.pessoaId)
        .then(() => {
          this.obterVinculosPorMatricula();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // CONTATOS:
    contatos(pessoa) {
      this.$emit('contato-vinculo', 'vinculos', pessoa.pessoaId);
    },
    // UTÉIS:
    limpaModel() {
      this.pessoa = {
        id: undefined,
        nome: '',
        pessoa: {},
        tipoFiliacao: '',
        tipoFiliacaoDescricao: '',
        tipoResponsavel: '',
        tipoResponsavelDescricao: '',
      };
    },
  },
};
</script>
